




import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getView } from '@/utils/helpers';

@Component({
  components: {
    TasqsDesktop: () => getView('TasqsDesktop'),
    TasqsMobile: () => getView('TasqsMobile'),
  },
})
export default class Tasqs extends Vue {
   windowWidth = window.innerWidth;

   get myView() {
     return this.windowWidth < 769 ? 'tasqs-mobile' : 'tasqs-desktop';
   }

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }

   created(){
     tasqsListModule.setUsernamesList(accountModule.user.email.toLowerCase())
   }

   beforeDestroy() {
     window.removeEventListener('resize', this.onResize);
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }



   
}
